.header{
  position: sticky;
  top: 0; 
  z-index: 100; 

  width: 100%;
  height: 80px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;


  &__content {
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    span {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333;
    }
  }

  &__menu-icon {
    display: none;
    flex-direction: column;
    cursor: pointer;

    .bar {
      width: 25px;
      height: 3px;
      background-color: #333;
      margin: 4px 0;
      transition: 0.3s;

      &.open:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      &.open:nth-child(2) {
        opacity: 0;
      }
      &.open:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }

  &__nav {
    display: flex;
    gap: 20px;

    a {
      position:relative;
      font-size: 1rem;
      font-weight: 600;
      color: #333;
      text-decoration: none;
      padding: 8px 12px;
      border-radius: 4px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #e9ecef;
        color: #007bff;
      }
      &:not(:last-child)::after {
        content: "|";
        position: absolute;
        right: -12px; // Position separator to the right of the text
        color: #ddd;
      }
    }

    .header__dropdown {
      position: relative;
      cursor: pointer;
      display:flex;
      padding: 8px 0; // Match padding with other links
      font-size: 1rem;
      font-weight: 600;
      color: #333;
      text-decoration: none;
      cursor: pointer;


      .dropdown-arrow {
        margin-left: 5px;
        font-size: 12px;
      }

      &-menu {
        position: absolute;
        top: calc(100% + 8px); 
        right: 0;
        background: #fff;
        border: 1px solid #ddd;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        z-index: 10;
        padding: 8px 0; 

        .dropdown-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
          gap: 10px; 
          border-bottom: 1px solid #f1f1f1; 
          &:last-child {
            border-bottom: none; 
          }

          span {
            font-weight: 600;
            color: #333;
          }

          a {
            text-decoration: none;
            color: #007aff;
            font-weight: 500;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__mobile-menu {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    border-radius: 8px; 
    z-index: 1000;


     .mobile-menu__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
      .dropdown-arrow {
        margin-left: 5px;
        font-size: 12px;
      }

      .mobile-menu__submenu {
        width: 100%;
        border: 1px solid #ddd; 
        border-radius: 8px;
        padding: 10px; 
        background-color: #fff;
  
        .dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0; 
          border-bottom: 1px solid #f1f1f1; 
          
          &:last-child {
            border-bottom: none; 
          }
          span {
            font-weight: 600;
            color: #333;
          }

          a {
            text-decoration: none;
            color: #007aff;
            font-weight: 500;
            margin-left: 10px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    

    .mobile-menu__link {
      display: block;
      padding: 12px 0;
      font-size: 1rem;
      font-weight: 600;
      color: #333;
      text-decoration: none;
      border-bottom: 1px solid #ddd;

      &:hover {
        color: #007bff;
      }
    }
  }

  @media (max-width: 768px) {
    &__menu-icon {
      display: flex;
    }

    &__nav {
      display: none;
    }
  }
}
