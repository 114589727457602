.image-card-animation {
  width: 559px;
  height: 625px;
  border-radius: 16px;

  .frame {
    width: 559px; 
    height: 625px; 
    overflow: hidden; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px solid #ddd;
    border-radius: 16px;
  .desktop-view {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2rem;
    
          .column {
            display: flex;
            flex-direction: column;
            gap: 1rem;
    
            .card {
              width: 247px;
              height: 354px;
              overflow: hidden;
              border-radius: 16px;
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

    .move-up {
      animation: moveUp 10s linear infinite;
    }

    .move-down {
      animation: moveDown 10s linear infinite;
    }
  }
}

  .mobile-view {
    display: none; 
    gap: 1rem;
    padding: 0.5rem; 
    white-space: nowrap;
    position: relative;

    .scroll-row {
      display: flex;
      gap: 1rem;
      animation: scrollHorizontal 20s linear infinite; 
    }

    .card {
      width: 150px;
      height: 200px;
      border-radius: 15px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-25%);
    }
  }

 
  @keyframes moveDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(25%);
    }
  }

  @keyframes scrollHorizontal {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-25%); 
    }
  }

  @media (max-width: 768px) {
    .desktop-view {
      display: none; 
    }

    .mobile-view {
      display: flex; 
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
    }
  }
}
