.blog-card {
  width: 493px;
  height: 485px;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .blog-image-section {
    position: relative;

    .blog-image {
      width: 493px;
      height: 320px;
      object-fit: cover;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .blog-blood-pressure {
      position: absolute;
      bottom: 24px;
      left: 24px;
      background: rgba(255, 255, 255, 0.9);
      padding: 8px 16px;
      border-radius: 20px;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 8px;

      .blog-icon {
        width: 24px;
        height: 21px;
        object-fit: contain; 
      }
      .blog-pressure-text {
        font-weight: bold;
        color: #333;

        span {
          font-size: 12px;
          vertical-align: super;
          color: #666;
        }
      }
    }
  }

  .blog-text-section {
    width: 438px;
    height: 107px;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .blog-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      color: #333;
    }

    .blog-description {
      font-size: 14px;
      color: #666;
      line-height: 1.5;
    }
  }
}
