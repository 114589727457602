.category-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;  
  width: 100%;
  padding-left: 0px;
  padding-bottom: 15px;
  margin: 0 0px; 
}

.arrow-btn-container {
  display: flex;
  justify-content: flex-end; 
  align-items: center;       
  gap: 10px;              
  margin-right: 5%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .category-nav {
    display: none; 
  }
  .category-title {
    display: none;
  }
  .arrow-btn {
    display: none;
  }
}

.category-title {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid #000;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-title.active {
  background-color: #000;
  color: white;
}

.arrow-btn-container {
  display: flex;
  justify-content: flex-end; 
  gap: 10px;  
  margin-left: auto;

}

.arrow-btn {
  background-color: white;
  border: 1px solid #000;
  color: black;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.arrow-btn:hover {
  background-color: #f1f1f1;
}

.blog-list-container {
  position: relative;
}

.blog-list {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 30px;
  padding: 20px 0;
}

.blog-item {
  min-width: 300px;
  flex-shrink: 0;
}

